/* eslint-disable @typescript-eslint/indent */
import React, { FC, useState, useEffect, useCallback } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { TypedUseSelectorHook, useSelector, useDispatch } from 'react-redux';
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles';
import { useQueries } from 'react-query';
import { GetSavedFiltersData, ResponseType } from '../../../../api';
import { TabPanel, CenteredTabs, CancelModel } from '../../../../components';
import s from './style/LocationFilter.module.scss';
import TagsInput from '../TagsInput';
import Dropdown from '../Dropdown';
import {
  setTemp,
  setActive,
  setIsFirstOpen,
  setIsModalOpen,
} from '../../Slice/impactReportSlice';
import type { RootState } from '../../../../store/store';
import MarkIcon from './assets/marcIcon.svg';
import ArrowIcon from './assets/arrow.svg';

const SavedFilterModal = React.lazy(() => import('./widgets/SavedFilterModal'));
const AlertModal = React.lazy(() => import('./widgets/AlertModal'));
const RecentSection = React.lazy(() => import('./widgets/RecentSection'));
const SavedSection = React.lazy(() => import('./widgets/SavedSection'));

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: '14px 20px',
    },
    closeButton: {
      position: 'absolute',
      padding: '0px',
      right: '16px',
      top: '16px',
      color: theme.palette.grey[500],
    },
    button: {
      background: 'green',
      padding: '600px',
      height: '527px',
    },
  });
const useStyles = makeStyles({
  cancelButton: {
    '&:hover': {
      filter: 'drop-shadow(0px 2px 15px rgba(122, 137, 167, 0.25))',
      borderRadius: '4px!important',
      background: 'white!important',
      color: '#282828!important',
    },
  },
  ghostButton: {
    '&:hover': {
      borderRadius: '4px!important',
      background: 'white!important',
      color: '#282828!important',
    },
  },
  submitButton: {
    backgroundColor: '#52B36B',
    '&:hover': {
      backgroundColor: '#52B36B!important',
      opacity: 0.8,
      filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
    },
  },
  button: {
    height: '57px',
    '&:hover': {
      boxShadow: '0px 2px 15px rgb(0 0 0 / 15%)!important',
      borderRadius: '4px!important',
      background: 'white!important',
      color: '#282828!important',
    },
  },
});

type LocationFilterType = {
  fullWindow: boolean;
  open: boolean;
  setOpen: Function;
};

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const headerStyles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: 0,
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1.5),
      color: theme.palette.grey[500],
    },
    button: {
      background: 'green',
      padding: '600px',
      height: '527px',
    },
  });
const DialogTitle = withStyles(headerStyles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose && (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <img src="/static/media/closeIcon.0fabd0b1.svg" alt="close icon" />
        </IconButton>
      )}
    </MuiDialogTitle>
  );
});

interface ISavedData {
  description: string;
  name: string;
  id: number;
  locationType: number;
  items: Array<{ value: string; entityId: string }>;
}

const LocationFilter: FC<LocationFilterType> = ({
  fullWindow,
  setOpen,
  open,
}) => {
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [isCancel, setIsCancel] = useState<boolean>(false);
  const [isAlertModal, setIsAlertModal] = useState<boolean>(false);
  const [isSavedModal, setIsSavedModal] = useState<boolean>(false);
  const [tabsValue, setTabsValue] = useState(0);

  const results = useQueries([GetSavedFiltersData()]);
  const savedData = results[0].data as ResponseType<Array<ISavedData>>;
  const dispatch = useDispatch();

  const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
  const items = useAppSelector((state) => state.impactReport);

  useEffect(() => {
    dispatch(setIsModalOpen(fullWindow));
  }, [dispatch, fullWindow]);

  useEffect(() => {
    setIsDisabled(items.tagsData.length === 0);
  }, [items.tagsData]);

  const handleChangeTabs = useCallback(
    (event: React.ChangeEvent<{}>, newValue: number) => {
      setTabsValue(newValue);
    },
    []
  );

  const handleClickOpen = useCallback(() => {
    setOpen(true);
    dispatch(setTemp());
    dispatch(setIsModalOpen(true));
  }, [dispatch, setOpen]);

  const declinedFunction = useCallback(() => {
    setOpen(false);
    dispatch(setTemp());
    dispatch(setIsModalOpen(true));
    setIsCancel(false);
  }, [dispatch, setOpen]);

  const handleClose = useCallback(() => {
    if (fullWindow) return;
    if (
      items.tempLocation === items.activeLocation &&
      items.activeTagsData === items.tagsData
    ) {
      declinedFunction();
    } else {
      setIsCancel(true);
    }
  }, [fullWindow, items, declinedFunction]);

  const handleConfirm = useCallback(() => {
    if (isDisabled) return;
    setOpen(false);
    dispatch(setActive());
    if (fullWindow) dispatch(setIsFirstOpen());
    setTabsValue(0);
  }, [dispatch, fullWindow, isDisabled, setOpen]);

  const classes = useStyles();

  return (
    <>
      <CancelModel
        title="Unsaved changes"
        applyBtnText="Save changes"
        cancelBtnText="Don't save"
        description="You have unsaved changes. Do you want to save?"
        isOpen={isCancel}
        stopEdit={declinedFunction}
        continueEdit={() => setIsCancel(false)}
      />
      <React.Suspense fallback={<>Loading...</>}>
        <>
          <AlertModal
            isOpen={isAlertModal}
            closeFunc={() => setIsAlertModal(false)}
          />

          <SavedFilterModal
            isOpen={isSavedModal}
            closeFunc={() => setIsSavedModal(false)}
          />
        </>
      </React.Suspense>

      <Button
        size="large"
        variant="contained"
        startIcon={
          <img style={{ marginLeft: '14px' }} src={MarkIcon} alt="mark icon" />
        }
        endIcon={
          <img
            src={ArrowIcon}
            style={{ marginRight: '14px' }}
            alt="arrow icon"
          />
        }
        color="secondary"
        style={{
          background: '#FFFFFF',
          color: '#282828',
          height: '48px',
          padding: '0px',
          width: '100%',
          display: 'flex',
          justifyContent: 'space-evenly',
          alignItems: 'center',
        }}
        onClick={handleClickOpen}
        className={classes.button}
      >
        <span className={`${s.areaBlock}`}>
          Selected Area:
          <span className={`${s.areaBlock__mainText}`}>
            {items.activeLocation.value}
          </span>
        </span>
      </Button>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          maxWidth: '100%',
          width: '100%',
        }}
      >
        <DialogTitle id="alert-dialog-title" onClose={handleClose} />
        <DialogContent
          style={{
            borderTop: 'none',
            padding: '24px 34px',
            maxWidth: '100%',
            width: tabsValue === 0 ? '692px' : '1000px',
            minHeight: '524px',
            overflow: 'hidden',
          }}
        >
          <CenteredTabs
            styleType="secondary"
            value={tabsValue}
            handleChange={handleChangeTabs}
            tabs={[
              { label: 'Location Filters' },
              { label: 'Saved Filters' },
              { label: 'Recents' },
            ]}
          >
            <div style={{ marginTop: '36px' }}>
              <TabPanel value={tabsValue} index={0}>
                <DialogContentText id="alert-dialog-description">
                  <div className={s.content}>
                    <p className={s.content__label}>Location Type</p>
                    <div className={s.content__main}>
                      <Dropdown />
                    </div>
                    {items.tempLocation.value !== 'All Geographies' && (
                      <>
                        <p className={s.content__label}>Location Names</p>
                        <div className={s.content__main}>
                          <TagsInput />
                        </div>
                      </>
                    )}
                  </div>
                </DialogContentText>
              </TabPanel>
              <TabPanel value={tabsValue} index={1}>
                {results[0].isLoading ? (
                  <p>Loading...</p>
                ) : (
                  <React.Suspense fallback={<>Loading...</>}>
                    <SavedSection
                      data={savedData?.result || []}
                      handleConfirm={handleConfirm}
                    />
                  </React.Suspense>
                )}
              </TabPanel>
              <TabPanel value={tabsValue} index={2}>
                <React.Suspense fallback={<>Loading...</>}>
                  <RecentSection handleConfirm={handleConfirm} />
                </React.Suspense>
              </TabPanel>
            </div>
          </CenteredTabs>
        </DialogContent>
        {tabsValue === 0 && (
          <DialogActions>
            {items.tempLocation.value !== 'All Geographies' &&
              items.tagsData.length >= 1 && (
                <Button
                  onClick={() => {
                    savedData.result.length >= 200
                      ? setIsAlertModal(true)
                      : setIsSavedModal(true);
                  }}
                  size="large"
                  variant="contained"
                  color="secondary"
                  className={classes.ghostButton}
                  style={{ padding: '8px 12px', fontSize: '20px' }}
                >
                  <span className={`${s.btn}`}>+ Save filter</span>
                </Button>
              )}
            <Button
              onClick={handleClose}
              size="large"
              variant="contained"
              color="secondary"
              disabled={fullWindow}
              className={classes.cancelButton}
              style={{ border: '2px solid #EFF2F7', fontSize: '20px' }}
            >
              <span className={`${s.btn}`}>Cancel</span>
            </Button>
            <Button
              onClick={handleConfirm}
              size="large"
              className={classes.submitButton}
              variant="contained"
              color="primary"
              disabled={isDisabled}
            >
              <span className={`${s.btn}`}>Apply</span>
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </>
  );
};

export default React.memo(LocationFilter);
